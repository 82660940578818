import DividerHorizontal from '@shared/components/Organism/ScheduleDetailsModal/partials/DividerHorizontal';
import classes from './index.module.scss';

import BaseButton from '@shared/uikit/Button/BaseButton';
import Flex from '@shared/uikit/Flex';
import Icon from '@shared/uikit/Icon';
import Typography from '@shared/uikit/Typography';
import { useTranslation } from '@shared/utils-pkg';
import Button from '@shared/uikit/Button';

type Props = {
  onClick: (() => any) | undefined;
  text?: string;
  isOpen?: boolean;
};

export function SeeAllButtonDivider({
  onClick,
  text = 'see_more',
  isOpen = false,
}: Props) {
  const { t } = useTranslation();
  return (
    <Flex className={classes.seeAllWrapper}>
      <DividerHorizontal className={classes.divider} />
      <Button
        rightIcon={isOpen ? '"chevron-up"' : 'chevron-down'}
        rightSize={16}
        labelFont="bold"
        schema="ghost"
        label={t(text)}
        onClick={onClick}
        className={classes.seeAllButton}
      />
      <DividerHorizontal className={classes.divider} />
    </Flex>
  );
}
