import classes from './contactInfo.useData.module.scss';

import React from 'react';
import { useTranslation, checkLinkPrefix, mailTo, callTo } from '@lobox/utils';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Typography from '@lobox/uikit/Typography';
import DisplayPhoneNumber from '@lobox/uikit/PhoneInput/DisplayPhoneNumber';
import { WEB_SITE } from '@shared/constants/profileModalsKeys';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import { useHasPermission, useProfilePage } from '../../../../../hooks';

type ContactInfoType = {
  id: string;
  title: string;
  subTitle?: string;
  value?: any;
  icon: string;
  to?: string;
};

const useContactInfoSectionData = (): Array<ContactInfoType> => {
  const { t } = useTranslation();
  const { getObjectProp, isPageCreation } = useProfilePage();
  const email = getObjectProp({ pageKey: 'email' });
  const phone = getObjectProp({ pageKey: 'phone' });
  const link = getObjectProp({ pageKey: 'link' });
  const { isAuthBusinessPage } = useProfilePage();
  const hasEditAccess = useHasPermission([SCOPES.canEditProfile]);

  const hasAddAccess = hasEditAccess && (isAuthBusinessPage || isPageCreation);

  return [
    {
      id: 'emil',
      title: t('email_address'),
      subTitle: hasAddAccess ? t('add_email') : t('no_email_ent'),
      icon: 'envelope',
      value: email ? (
        <BaseButton
          {...(isAuthBusinessPage ? {} : { href: mailTo(email as string) })}
        >
          <Typography
            size={15}
            color="thirdText"
            mt={5}
            className={classes.subtitle}
            height={21}
            isTruncated
          >
            {email}
          </Typography>
        </BaseButton>
      ) : undefined,
    },
    {
      id: 'phone',
      title: t('phone'),
      subTitle: hasAddAccess ? t('add_phone') : t('no_p_number_ent'),
      value: phone ? (
        <BaseButton
          {...(isAuthBusinessPage ? {} : { href: callTo(phone as string) })}
        >
          <DisplayPhoneNumber
            inputClassName={classes.phoneStyle}
            value={phone}
          />
        </BaseButton>
      ) : undefined,
      icon: 'phone',
      to: 'settings',
    },
    {
      id: WEB_SITE,
      title: t('website'),
      subTitle: hasAddAccess ? t('add_website') : t('no_web_ent'),
      value: link ? (
        <BaseButton
          {...(isAuthBusinessPage
            ? {}
            : { href: checkLinkPrefix(link as string) })}
          target="_blank"
        >
          <Typography
            size={15}
            color="thirdText"
            mt={5}
            className={classes.subtitle}
            height={21}
            isTruncated
          >
            {link}
          </Typography>
        </BaseButton>
      ) : undefined,
      icon: 'link',
    },
  ];
};

export default useContactInfoSectionData;
