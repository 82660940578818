import React from 'react';
import { useTranslation, formatDate, routeNames } from '@lobox/utils';
import RichTextView from '@lobox/uikit/RichText/RichTextView';
import { useHistory } from '@lobox/utils';
import useHasPermission from '@shared/hooks/useHasPermission';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import { useProfilePage } from '../../../../../hooks';
import useNavigateSearchPage from '@shared/hooks/useNavigateSearchPage';
import { useObjectClicks } from '@shared/hooks/useObjectClicks';

type OverviewSectionType = {
  id: string;
  title: string;
  subTitle?: string;
  value?: any;
  icon: string;
  to?: string;
};

const useOverviewSectionData = (): Array<OverviewSectionType> => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getObjectProp, isAuthBusinessPage, isPageCreation } =
    useProfilePage();
  const category =
    getObjectProp({ pageKey: ['category', 'value'] }) || 'COMPANY';
  const description = getObjectProp({ pageKey: 'description' }) as string;
  const industry = getObjectProp({
    pageKey: ['industry', 'label'],
  });
  const establishmentDate = getObjectProp({
    pageKey: 'establishmentDate',
  }) as string;
  const companySize = getObjectProp({ pageKey: ['companySize', 'label'] });
  const canSeeHomeScreen = useHasPermission([SCOPES.canSeeHomeScreen]);
  const handleMentionClicked = () => {};

  const { handleHashtagClick } = useObjectClicks();

  const hasEditAccess = useHasPermission([SCOPES.canEditProfile]);

  const categoryField = {
    id: 'industry',
    title: t('category'),
    subTitle: t('add_category'),
    value: industry,
    icon: 'list-ul',
  };
  const descriptionField = (isAuthBusinessPage ||
    description ||
    isPageCreation) && {
    id: 'description',
    title: t('description'),
    subTitle: hasEditAccess ? t('add_description') : t('no_desc_ent'),
    value: description ? (
      <RichTextView
        html={description}
        // showMore
        row={4}
        showMore={false}
        // limit={300}
        typographyProps={{
          color: 'thirdText',
          isWordWrap: true,
        }}
        onMentionClick={handleMentionClicked}
        onHashtagClick={handleHashtagClick}
      />
    ) : undefined,
    icon: 'info-circle',
  };
  const establishmentDateField = (isAuthBusinessPage ||
    establishmentDate ||
    isPageCreation) && {
    id: 'establishment_date',
    title: t('establishment_date'),
    subTitle: t('add_establishment_date'),
    value: establishmentDate ? formatDate(establishmentDate, 'y') : undefined,
    icon: 'calendar-alt',
  };
  const companySizeField = (isAuthBusinessPage ||
    companySize ||
    isPageCreation) && {
    id: 'page_size',
    title: t('page_size'),
    subTitle: t('add_page_size'),
    value: t(companySize),
    icon: 'users',
  };
  const institutionField = (isAuthBusinessPage ||
    companySize ||
    isPageCreation) && {
    ...companySizeField,
    title: t('institution_size'),
    subTitle: t('add_inst_size'),
  };

  const getGroupsByCategory = {
    COMPANY: [
      categoryField,
      descriptionField,
      establishmentDateField,
      companySizeField,
    ].filter(Boolean),
    ARTIST: [categoryField, descriptionField].filter(Boolean),
    COMMUNITY: [categoryField, descriptionField].filter(Boolean),
    EDUCATION_INSTITUTION: [
      categoryField,
      descriptionField,
      establishmentDateField,
      institutionField,
    ].filter(Boolean),
    INSTITUTION: [
      categoryField,
      descriptionField,
      establishmentDateField,
      institutionField,
    ].filter(Boolean),
    NON_PROFIT_ORGANIZATION: [
      categoryField,
      descriptionField,
      establishmentDateField,
      {
        ...companySizeField,
        title: t('organization_size'),
        subTitle: t('add_organization_size'),
      },
    ].filter(Boolean),
    PUBLIC_FIGURE: [categoryField, descriptionField].filter(Boolean),
    PUBLIC_SERVICE: [
      categoryField,
      descriptionField,
      establishmentDateField,
      {
        ...companySizeField,
        title: t('organization_size'),
        subTitle: t('add_organization_size'),
      },
    ].filter(Boolean),
  };
  // @ts-ignore
  return getGroupsByCategory[category];
};

export default useOverviewSectionData;
