import classes from './Overview.component.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import { PAGE_ROLES, useTranslation } from '@lobox/utils';
import { CREATE_PAGE_FORM } from '@shared/constants/profileModalsKeys';
import useHasPermission from '@shared/hooks/useHasPermission';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import { useProfileModals, useProfilePage } from '@shared/hooks';
import InfoCard from '../../Common/InfoCard';
import SectionLayout from '../../Common/Section.layout';
import useOverviewData from './overview.useData';

const Overview: React.FC = () => {
  const { t } = useTranslation();
  const data = useOverviewData();
  const { open } = useProfileModals();
  const { isAuthBusinessPage, objectDetail, hasMembership, roles } =
    useProfilePage();
  const hasEditAccess = useHasPermission([SCOPES.canEditProfile]);
  const canEditProfile =
    roles.includes(PAGE_ROLES.ADMIN.value) ||
    roles.includes(PAGE_ROLES.EDITOR.value);
  const enabled =
    (isAuthBusinessPage && hasEditAccess) ||
    (!isBusinessApp && hasMembership && canEditProfile);

  const onClick = () => open(CREATE_PAGE_FORM, objectDetail);

  return (
    <SectionLayout
      title={t('overview')}
      classNames={{ childrenWrap: classes.childrenWrap }}
      onClick={onClick}
      visibleActionButton={false}
    >
      {data.map(({ id, title, subTitle, value, icon }, index) => (
        <BaseButton
          key={id}
          onClick={enabled ? onClick : undefined}
          disabled={!enabled}
        >
          <InfoCard
            {...{
              disabledHover: !enabled,
              title,
              subTitle,
              value,
              icon,
              border: index !== data.length - 1,
            }}
          />
        </BaseButton>
      ))}
    </SectionLayout>
  );
};

export default Overview;
