import classes from './SectionTitle.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Typography from '@lobox/uikit/Typography';
import type { TextProps } from '@lobox/uikit/Typography';

interface SectionTitleProps {
  title: React.ReactNode;
  titleHelper?: string;
  actionButton?: React.ReactNode;
  wrapperStyle?: string;
  titleHelperProps?: TextProps;
  handleBackClick?: () => void;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  titleHelper,
  actionButton,
  wrapperStyle,
  titleHelperProps = {},
  handleBackClick,
}) => {
  return (
    <Flex className={cnj(classes.sectionTitleWrap, wrapperStyle)}>
      <Flex className={classes.row}>
        {!!handleBackClick && (
          <IconButton
            size="md15"
            iconProps={{
              type: 'far',
            }}
            name="chevron-left"
            colorSchema="transparent"
            className={classes.backButton}
            onClick={handleBackClick}
          />
        )}
        <Typography font="700" size={20} height={24}>
          {title}
        </Typography>
        {titleHelper && (
          <>
            <DividerVertical height={20} />
            <Typography font="400" size={15} height={24} {...titleHelperProps}>
              {titleHelper}
            </Typography>
          </>
        )}
      </Flex>
      {actionButton}
    </Flex>
  );
};

export default SectionTitle;
