import classes from './Section.layout.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import { useTranslation } from '@lobox/utils';
import type { TextProps } from '@lobox/uikit/Typography';
import SectionTitle from './SectionTitle';
import { SeeAllButtonDivider } from '@shared/components/molecules/SeeAllButtonDivider';

type ClassNameProps = {
  title?: string;
  childrenWrap?: string;
  actionButton?: string;
};
type SectionLayoutProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  showAllHandler?: () => void;
  isVisibleShowMore?: boolean;
  classNames?: Partial<ClassNameProps>;
  actionButton?: React.ReactNode;
  visibleActionButton?: boolean;
  titleHelperProps?: TextProps;
  titleHelper?: string;
  handleBackClick?: () => void;
  onEditHandler?: () => void;
  isShowAll?: boolean;
};

const SectionLayout: React.FC<SectionLayoutProps> = ({
  title,
  children,
  onClick,
  classNames,
  isVisibleShowMore,
  isShowAll,
  showAllHandler,
  actionButton: actionBtn,
  visibleActionButton,
  titleHelper,
  titleHelperProps,
  handleBackClick,
  onEditHandler,
}) => {
  const { t } = useTranslation();

  const actionButton = () => {
    if (actionBtn) return actionBtn;
    if (!onClick && !onEditHandler) return null;

    return (
      <Flex flexDir="row">
        {!!onEditHandler && (
          <IconButton
            size="md15"
            colorSchema="transparent"
            onClick={onEditHandler}
            name="pen"
            iconProps={{ type: 'far' }}
          />
        )}
        {!!onClick && (
          <IconButton
            size="md15"
            colorSchema="transparent"
            onClick={onClick}
            name="plus"
            iconProps={{ type: 'far' }}
          />
        )}
      </Flex>
    );
  };

  return (
    <>
      <SectionTitle
        title={title}
        titleHelper={titleHelper}
        titleHelperProps={titleHelperProps}
        wrapperStyle={cnj(classes.wrapper, classNames?.title)}
        handleBackClick={handleBackClick}
        actionButton={
          visibleActionButton ? (
            <Flex
              className={cnj(classes.actionButton, classNames?.actionButton)}
            >
              {actionButton()}
            </Flex>
          ) : undefined
        }
      />
      <Flex className={cnj(classes.aboutWrap, classNames?.childrenWrap)}>
        {children}
        {isVisibleShowMore && (
          <SeeAllButtonDivider
            onClick={showAllHandler}
            text="see_all"
            isOpen={!isShowAll}
          />
        )}
      </Flex>
    </>
  );
};

export default SectionLayout;
