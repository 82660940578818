import classes from './ContactInfo.component.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import { PAGE_ROLES, useTranslation } from '@lobox/utils';
import { CREATE_PAGE_FORM } from '@shared/constants/profileModalsKeys';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import {
  useHasPermission,
  useProfileModals,
  useProfilePage,
} from '@shared/hooks';
import SectionLayout from '../../Common/Section.layout';
import InfoCard from '../../Common/InfoCard';
import useContactInfoData from './contactInfo.useData';

const ContactInfo: React.FC = () => {
  const { t } = useTranslation();
  const data = useContactInfoData();
  const { open } = useProfileModals();

  const { isAuthBusinessPage, roles, hasMembership, objectDetail } =
    useProfilePage();
  const hasEditAccess = useHasPermission([SCOPES.canEditProfile]);
  const canEditProfile =
    roles.includes(PAGE_ROLES.ADMIN.value) ||
    roles.includes(PAGE_ROLES.EDITOR.value);
  const enabled =
    (isAuthBusinessPage && hasEditAccess) ||
    (!isBusinessApp && hasMembership && canEditProfile);
  const onClick = () => open(CREATE_PAGE_FORM, objectDetail);

  return (
    <SectionLayout
      title={t('contact_info')}
      classNames={{ childrenWrap: classes.childrenWrap }}
      onClick={onClick}
      visibleActionButton={false}
    >
      {data.map(({ id, title, subTitle, value, icon }) => (
        <BaseButton
          disabled={!enabled}
          onClick={enabled ? onClick : undefined}
          key={id}
        >
          <InfoCard
            {...{
              disabledHover: !enabled,
              title,
              subTitle,
              value,
              icon,
              iconSize: 16,
            }}
          />
        </BaseButton>
      ))}
    </SectionLayout>
  );
};

export default ContactInfo;
